import "./libs/wdyr";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import {initializeI18n} from "./libs/i18next";

const promise: Promise<any> = initializeI18n();

promise
    .then(
        () => {
            return import("./main"); // Don't load app until i18n is initialized
        },
        error => {
            ReactDOM.render(<div>{error.toString()}</div>, document.getElementById("root"));
        }
    )
    .finally(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    });
